import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

// Importing images for the projects
import leaf from "../../Assets/Projects/Book store.png";
import emotion from "../../Assets/Projects/Menu.png";
import editor from "../../Assets/Projects/furniture m.png";
import chatify from "../../Assets/Projects/Profile Card UI - White.png";
import suicide from "../../Assets/Projects/donate.jpg";
import bitsOfCode from "../../Assets/Projects/calculator.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works</strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Book Store Landing Page"
              description="UI of book store"
            // Uncomment and add links if needed
            // ghLink="https://github.com/aakrity17/Boostim-Design"
            // demoLink="https://chatify-49.web.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Calculator"
              description="Calculator using HTML, CSS, and JavaScript"
              ghLink="https://github.com/aakrity17/Calculator"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Mobile app Ui Design of Furniture "
              description="Using Figma"
            // ghLink="https://github.com/aakrity17/Pradip_Portfolio_site"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Visit card"
              description="Created simple and attractive Ui of Vist card"
            // ghLink="https://github.com/aakrity17/Mini-Blogging-APP-React"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Charity and Donation Management System (Fundfusion)"
              description="Charity and donation project during the 6th semester"
              ghLink="https://github.com/aakrity17/FundFusion"
            />
          </Col>

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Car Rental Management System"
              description="Car rental management system project using VB.NET"
              ghLink="https://github.com/aakrity17/Car-Rental-Management-System-Using-Vb.net"
            />
          </Col> */}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
